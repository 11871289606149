// import { withSSRContext } from 'aws-amplify'

// export default async function fetchAccessToken(context) {
//   try {
//     const { Auth: AmplifySSRAuth } = withSSRContext(context)
//     const currentSession = await AmplifySSRAuth.currentSession()

//     return currentSession.accessToken.jwtToken
//   }
//   catch {
//     return null
//   }
// }

export default function fetchAccessToken(context) {
  const webClientId = context.app.$config.COGNITO_USER_POOL_WEB_CLIENT_ID
  const accessTokenCookie = `CognitoIdentityServiceProvider.${webClientId}.LastAuthUser`
  const lastAuthUserId = context.$cookies.get(accessTokenCookie)
  if (!lastAuthUserId) return null

  return context.$cookies.get(`CognitoIdentityServiceProvider.${webClientId}.${lastAuthUserId}.accessToken`)
}