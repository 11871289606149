const BMSV_BANNER_HIDDEN_COOKIE_KEY = 'bmsvBannerHidden'

import { mapGetters } from 'vuex'
import BuyMoreSaveMoreBanner from '@/components/BuyMoreSaveMoreBanner'

export default {
  data () {
    return {
      buyMoreSaveMoreExpiredTimer: null,
      buyMoreSaveMoreExpired: false,
      buyMoreSaveMoreExpiredBannerRenderKey: 1
    }
  },
  components: {
    BuyMoreSaveMoreBanner
  },
  computed: {
    ...mapGetters('order', ['getUrlCampaign']),
    buyMoreSaveMoreBannerVisible () {
      return (
        this.buyMoreSaveMoreExpiredBannerRenderKey &&
        ['bmsv-tier', 'bmsv-tier_subscriber'].includes(this.getUrlCampaign) &&
        !this.$cookies.get(BMSV_BANNER_HIDDEN_COOKIE_KEY) &&
        !this.buyMoreSaveMoreExpired
      )
    }
  },
  methods: {
    checkBuyMoreSaveMoreBannerExpired () {
      if (process.server) return

      const targetTimeString = this.$cookies.get('sanUCEA')
      if (!targetTimeString) {
        this.buyMoreSaveMoreExpired = true
        return
      }

      const targetTimestamp = new Date(targetTimeString).getTime()

      this.buyMoreSaveMoreExpiredTimer =
        setInterval(() => {
          if (targetTimestamp <= Date.now()) {
            this.buyMoreSaveMoreExpired = true
            this.clearBuyMoreSaveMoreExpiredTimer()
          }
        }, 500)
    },
    clearBuyMoreSaveMoreExpiredTimer () {
      clearInterval(this.buyMoreSaveMoreExpiredTimer)
    },
    hideBuyMoreSaveMoreBanner () {
      ++this.buyMoreSaveMoreExpiredBannerRenderKey
      this.$cookies.set(BMSV_BANNER_HIDDEN_COOKIE_KEY, true, { path: '/', maxAge: 24 * 60 * 60 })
    }
  }
}