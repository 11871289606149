import { cloneDeep, debounce } from 'lodash'
import ApiUser from '@/api/User'

const LOCAL_STORE_DESIGNER_DISCOUNT = 'sanDD'
const LOCAL_STORE_APPLIED_COUPON_CODE = 'sanACC'


export const state = () => ({
    applied_coupon_code: false,
    created_account_discount: false,
    designer_discount_applied: false,
    favorites: {
        products: {}
    },
    sms_notifications_enabled: false,
    user_data: null,
    user_address: null
})
export const getters = {
    isFavorite: state => product_id => {
        return !!state.favorites.products[product_id]
    },
    favoriteId: state => product_id => {
        return state.favorites.products[product_id]
    },
    getCreatedAccountDiscount: state => {
        return state.created_account_discount
    },
    getAppliedCouponCode: state => {
        return state.applied_coupon_code
    },
    getDesignerDiscountApplied: state => {
        return state.designer_discount_applied
    },
    getSmsNotificationsEnabled: state => {
        return state.sms_notifications_enabled
    },
    getUserData: state => {
        return state.user_data
    },
    getUserAddress: state => {
        return state.user_address
    }
}
export const mutations = {
    setDesignerDiscountApplied(state, payload) {
        state.designer_discount_applied = payload
    },
    setAppliedCouponCode(state, payload) {
        state.applied_coupon_code = payload
    },
    setCreatedAccountDiscount(state, payload) {
        state.created_account_discount = payload
    },
    addFavorite(state, payload) {
        let products = Object.assign(state.favorites.products, payload)
        state.favorites = { ...state.favorites, products }
    },
    setFavorites(state, payload) {
        state.favorites.products = payload
    },
    removeFavorite(state, payload) {
        let products = cloneDeep(state.favorites.products)
        products[payload] = undefined
        state.favorites = { ...state.favorites, products }
    },
    setUserData(state, payload) {
        state.user_data = payload
    },
    setUserAddress(state, payload) {
        state.user_address = payload
    },
    clearUser(state) {
        state.favorites.products = {}
    },
    clearUserData(state) {
        state.user_data = null
    },
    clearUserAddress(state) {
        state.user_address = null
    },
    setSmsNotificationsEnabled(state, payload) {
        state.sms_notifications_enabled = payload
    }
}
export const actions = {
    initUserData({ commit }, context) {
        commit('setDesignerDiscountApplied', context.$cookies.get(LOCAL_STORE_DESIGNER_DISCOUNT))
    },
    setDesignerDiscountApplied({ commit }, payload) {
        this.$cookies.set(LOCAL_STORE_DESIGNER_DISCOUNT, payload, { path: '/', maxAge: 2147483647 })
        commit('setDesignerDiscountApplied', payload)
    },
    setAppliedCouponCode({ commit }, payload) {
        this.$cookies.set(LOCAL_STORE_APPLIED_COUPON_CODE, payload, { path: '/', maxAge: 2147483647 })
        commit('setAppliedCouponCode', payload)
    },
    setCreatedAccountDiscount({commit}, payload) {
        commit('setCreatedAccountDiscount', payload)
    },
    addFavorite({commit}, payload) {
        commit('addFavorite', payload)
    },
    setFavorites({commit}, payload) {
        commit('setFavorites', payload)
    },
    removeFavorite({commit}, payload) {
        commit('removeFavorite', payload)
    },
    fetchUserData: debounce(async function ({commit}) {
        const { data } = await ApiUser.getUser(this.$axios)
        commit('setUserData', data)
    }, 1000),
    fetchUserAddress: debounce(async function ({commit}) {
        const { data } = await ApiUser.getAddress(this.$axios)
        commit('setUserAddress', data)
    }, 1000),
    clearUser({commit}) {
        commit('clearUser')
    },
    clearUserData({commit}) {
        commit('clearUserData')
    },
    clearUserAddress({commit}) {
        commit('clearUserAddress')
    },
    setSmsNotificationsEnabled({commit}, payload) {
        commit('setSmsNotificationsEnabled', payload)
    }
}

export {
    LOCAL_STORE_DESIGNER_DISCOUNT
}