<template>
  <div class="grid-application-layout">
    <modal/>

    <transition name="fade" class="grid-application-layout">
      <nuxt/>
    </transition>
  </div>
</template>

<script>
  import Modal from '@/components/Modal'

  export default {
    name: 'OauthCallback',
    components: {
      Modal
    }
  }
</script>