export default {
  label: 'Buy More, Save More',
  tiers: [
    { amount: 10, range: { to: 999.99 } },
    { amount: 15, range: { from: 1000, to: 2999.99 } },
    { amount: 20, range: { from: 3000, to: 9999.99 } },
    { amount: 25, range: { from: 10000 } }
  ],
  options: [
    'swap',
    'refresh',
    'renew',
    'transform'
  ],
  termsAndConditionsLink: {
    name: 'pages-url_handler',
    params: {
      url_handler: 'bmsv-tos'
    }
  },
  offerDetailsLink: {
    name: 'pages-url_handler',
    params: {
      url_handler: 'buy-more-save-more-offer-details'
    }
  }
}