import CouponCodeApplication from '@/utils/couponCodeApplication'
import DiscountGeneration from '@/utils/discountGeneration'
import fetchAccessToken from '@/utils/accessTokenFetch'

export default function(context) {
    let { store, error: nuxtError, app, app: { $axios } } = context

    $axios.onResponse(response => {
        const { data: { data }, config: { method, url } } = response
        const endpoint = `${method} ${url}`
        const couponCodeApplicationEndpoints = [
            'post /api/v1/orders/add_to_cart'
        ]

        if (couponCodeApplicationEndpoints.includes(endpoint)) {
            store.dispatch('order/setOrderId', data.id)
            store.dispatch('order/setOrder', data)
            CouponCodeApplication(context)()
            DiscountGeneration(context)()
        }
    })

    $axios.onError(error => {
        const response = error?.response
        const status = response?.status
        const data = response?.data
        const globalErrorCodes = [101]

        if (status === 500) {
            nuxtError({ statusCode: status })
            return new Promise(() => {})
        } else if (status === 422 && globalErrorCodes.includes(data.code) && data.details) {
            app.$store.dispatch('modal/showModal', { component: 'Information',  props: { info: data.details } })
            return new Promise(() => {})
        }

        return Promise.reject(error)
    })

    $axios.onRequest(config => {
        const firstReferrer = app.$cookies.get('sanFR')
        if (!!firstReferrer) config.headers['X-First-Referrer'] = firstReferrer

        const lastReferrer = app.$cookies.get('sanLR')
        if (!!lastReferrer) config.headers['X-Last-Referrer'] = lastReferrer

        const token = fetchAccessToken(context)
        if (!!token) config.headers.Authorization = `Bearer ${token}`

        return config
    })
}
