import User from '@/api/User'

import { Auth as AmplifyAuth } from 'aws-amplify'
import { isNil } from 'lodash'
import { LOCAL_STORE_DESIGNER_DISCOUNT } from './user'
import fetchAccessToken from '@/utils/accessTokenFetch'

import CordialMixin from '@/mixins/cordial'
import GtmHelpers from '@/mixins/gtmHelpers'
import PinterestConversionEvents from '@/mixins/pinterestConversionEvents'

const LOCAL_STORE_AUTH_ROLE = 'ecom:sanAR'
const LOCAL_STORE_EMAIL = 'ecom:sanEM'
const LOCAL_STORE_SIGNED_IN_STAGING = 'ecom:sanSIS'
const STAGING_PASSWORD = 'staging'

function clearAuthData ({ dispatch, commit }) {
  dispatch('setAxiosHeader', { name: 'Authorization', value: null })
  dispatch('removeDataItem', LOCAL_STORE_AUTH_ROLE)
  dispatch('removeDataItem', LOCAL_STORE_EMAIL)
  dispatch('removeDataItem', LOCAL_STORE_DESIGNER_DISCOUNT)

  commit('clearUser')
}

export const state = () => ({
  token: null,
  role: null,
  email: null,
  signedInStaging: false
})
export const getters = {
  signedAdmin: state => {
    return !!state.token && state.role === 'admin'
  },
  signedDesigner: state => {
    return !!state.token && (state.role === 'trade_designer' || state.role === 'room_designer')
  },
  signedIn: state => {
    return !!state.token
  },
  getEmail: state => {
    return state.email
  },
  getSignedInStaging: state => {
    return state.signedInStaging
  }
}
export const mutations = {
  setUser(state, payload) {
    state.token = payload.token
    state.role = payload.role
    state.email = payload.email
  },
  clearUser(state) {
    state.token = null
    state.role = null
    state.email = null
  },
  setSignedInStaging (state, payload) {
    state.signedInStaging = payload
  }
}
export const actions = {
  initAuth({ commit }, context) {
    let token, role, email, signedInStaging

    token = fetchAccessToken(context)
    role = context.$cookies.get(LOCAL_STORE_AUTH_ROLE)
    email = context.$cookies.get(LOCAL_STORE_EMAIL)
    signedInStaging = context.$cookies.get(LOCAL_STORE_SIGNED_IN_STAGING)

    commit('setUser', { token, role, email })
    commit('setSignedInStaging', signedInStaging)
  },
  initialUser(context) {
    const { state: { token }, commit, dispatch, rootGetters } = context

    dispatch('setAxiosHeader', { name: 'Authorization', value: token })

    return User.initialCheck(this.$axios, { order_id: rootGetters['order/getOrderId'] })
      .then(response => {
        let role = response.data.role
        let email = response.data.email
        let order_id = response.data.order_id
        let order = response.data.order
        let applied_coupon_code = response.data.applied_coupon_code
        let designer_discount_applied = response.data.designer_discount_applied
        let sms_notifications_enabled = response.data.sms_notifications_enabled
        let favorites = response.data.favorites

        this.$axios.setToken(token)

        dispatch('setDataItem', { name: LOCAL_STORE_AUTH_ROLE, value: role })
        dispatch('setDataItem', { name: LOCAL_STORE_EMAIL, value: email })

        dispatch('order/setOrderId', order_id, { root: true })
        dispatch('order/setOrder', order, { root: true })

        commit('setUser', { token, role, email })
        commit('user/setAppliedCouponCode', applied_coupon_code, { root: true })

        dispatch('user/setDesignerDiscountApplied', designer_discount_applied, { root: true })
        dispatch('user/setSmsNotificationsEnabled', sms_notifications_enabled, { root: true })
        dispatch('user/setFavorites', favorites, { root: true })

        const cartData = rootGetters['order/getCartCordial']
        if (process.client) {
          CordialMixin.methods.identifyCordialUser(email, cartData)
          GtmHelpers.methods.gtmSignup()
        }

      })
      .catch(error => {
        clearAuthData(context)

        if (!!error.response.data.remove_order) {
          dispatch('order/clearOrderId', {}, {root: true})
          dispatch('order/clearOrder', {}, {root: true})
        }
      })
  },
  signUp(context, { email, password }) {
    return new Promise((resolve, reject) => {
      AmplifyAuth.signUp({ username: email, password })
        .then(response => {
          if (process.client) {
            CordialMixin.methods.identifyCordialUser(email)
            PinterestConversionEvents.methods.pinterestSignup()
          }

          resolve(response)
        })
        .catch(error => {
          clearAuthData(context)
          reject(error)
        })
    })
  },
  stagingSignIn ({ dispatch, commit }, password) {
    if (password !== STAGING_PASSWORD) return false

    dispatch('setDataItem', { name: LOCAL_STORE_SIGNED_IN_STAGING, value: true })
    commit('setSignedInStaging', true)

    return true
  },
  signIn(context, { email, password }) {
    return new Promise((resolve, reject) => {
      AmplifyAuth.signIn(email, password)
        .then(response => {
          const token = response.signInUserSession.jwtToken

          context.dispatch('setAxiosHeader', {
            name: 'Authorization',
            value: `Bearer ${token}`
          })

          context.commit('setUser', { token })

          resolve(response)
        })
        .catch(error => {
          console.dir(error)

          console.error(error)
          clearAuthData(context)
          reject(error)
        })
    })
  },
  signOut (context) {
    const { dispatch } = context

    return new Promise((resolve, reject) => {
      clearAuthData(context)

      dispatch('user/clearUser', {}, { root: true })
      dispatch('order/clearOrderId', {}, { root: true })
      dispatch('order/clearOrder', {}, { root: true })

      if (process.client) {
        CordialMixin.methods.forgetCordialUser()
      }

      AmplifyAuth.signOut().then(resolve).catch(reject)
    })
  },
  setCurrentSession (context) {
    return new Promise((resolve, reject) => {
      AmplifyAuth.currentSession()
        .then(response => {
          const token = response.accessToken.jwtToken

          context.dispatch('setAxiosHeader', {
            name: 'Authorization',
            value: `Bearer ${token}`
          })
          context.commit('setUser', { token })

          resolve(response)
        })
        .catch(error => {
          clearAuthData(context)
          reject(error)
        })
    })
  },
  setDataItem(_, payload) {
    let defaultOpts = { path: '/' }

    if (!payload.opts) {
      payload.opts = { maxAge: 3600 * 24 }
    }
    this.$cookies.set(payload.name, payload.value, { ...defaultOpts, ...payload.opts })
  },
  removeDataItem(_, name) {
    this.$cookies.remove(name)
  },
  setAxiosHeader(_context, payload) {
    if (isNil(payload.value)) {
      delete this.$axios.defaults.headers.common[payload.name]
    } else {
      this.$axios.defaults.headers.common[payload.name] = payload.value
    }
  }
}
