import { v4 as uuidv4 } from 'uuid'
import { merge, last, get } from 'lodash'
import ApiConversionEvent from '@/api/ConversionEvent'

export default {
  data () {
    return {
      fingerprint: null,
      fingerprintLoadedCallbacks: []
    }
  },
  async mounted () {
    await this.loadFingerprint()
  },
  computed: {
    isProduction () {
      return $nuxt.$options.$config.PRODUCTION
    }
  },
  methods: {
    async loadFingerprint () {
      if (!!this.fingerprint || !this.isProduction) return

      try {
        const fingerprint = await this.$getFingerprint()
        const result = await fingerprint.get()

        this.fingerprint = result.visitorId
        this.fingerprintLoadedCallbacks.forEach(callback => callback())
      }
      catch (error) {
        console.error(error)
      }
    },
    pinterestCheckoutEvent (order) {
      this.sendRequests({
        tagData: this.getCheckoutTagData(order),
        conversionData: this.getCheckoutConversionData(order)
      })
    },
    pinterestProductPageVisit (product, eventId) {
      this.sendRequests({
        eventId,
        tagData: this.getProductPageVisitTagData(product),
        conversionData: this.getProductPageVisitConversionData(product)
      })
    },
    pinterestAddToCart (order, product) {
      this.sendRequests({
        tagData: this.getAddToCartTagData(order, product),
        conversionData: this.getAddToCartConversionData(order, product)
      })
    },
    pinterestSearch (order, product) {
      this.sendRequests({
        tagData: this.getSearchTagData(order, product),
        conversionData: this.getSearchConversionData(order, product)
      })
    },
    pinterestSignup () {
      this.sendRequests({
        tagData: this.getSignupTagData(),
        conversionData: this.getSignupConversionData()
      })
    },
    pinterestViewCategory (name, products) {
      this.sendRequests({
        tagData: this.getViewCategoryTagData(name, products),
        conversionData: this.getViewCategoryConversionData(name, products)
      })
    },
    pinterestLead () {
      this.sendRequests({
        tagData: this.getLeadTagData(),
        conversionData: this.getLeadConversionData()
      })
    },
    getCheckoutTagData (order) {
      return this.getOrderTagData(order)
    },
    getAddToCartTagData (order, _product) {
      return this.getOrderTagData(order)
    },
    getProductPageVisitTagData (product) {
      return {
        line_items: [{
          product_id: product.id,
          product_name: product.name,
          product_category: product.category,
          product_price: product.price,
          product_quantity: product.quantity,
          product_brand: product.brand
        }]
      }
    },
    getSearchTagData (searchQuery) {
      return { search_query: searchQuery }
    },
    getSignupTagData () {
      return {}
    },
    getLeadTagData () {
      return {}
    },
    getViewCategoryTagData (_name, products) {
      const lineItems =
        products.map(product => {
          const category = get(last(Object.entries(product.category || {})), [1, 0])

          return {
            product_name: product.proper_name,
            product_id: product.objectID,
            product_category: category,
            product_price: product.price,
            product_quantity: 1,
            product_brand: product.supplier?.name
          }
        })

      return { line_items: lineItems }
    },
    getOrderTagData ({ line_items, total, number }) {
      const lineItems = line_items.map(line_item => {
        return {
          product_name: line_item.variant.name,
          product_id: line_item.variant.id,
          product_category: line_item.category,
          product_price: line_item.price,
          product_quantity: line_item.quantity,
          product_brand: line_item.supplier_name
        }
      })

      return {
        value: total,
        order_id: number,
        order_quantity: lineItems.length,
        line_items: lineItems
      }
    },
    sendRequests ({ eventId, tagData, conversionData }) {

      if (!this.isProduction) return

      eventId = eventId || uuidv4()
      const event = this.getPinterestTagEvent(conversionData)

      this.sendPinterestTag(event, eventId, tagData)

      if (!!this.fingerprint) {
        this.sendRequestToBackend(eventId, conversionData)
      } else {
        this.fingerprintLoadedCallbacks?.push(() => this.sendRequestToBackend(eventId, conversionData))
      }
    },
    sendRequestToBackend (eventId, payload) {
      ApiConversionEvent
        .storeConversionEvent($nuxt.$axios, merge(payload, { event_id: eventId, fingerprint: this.fingerprint }))
        .catch(error => console.error(error))
    },
    sendPinterestTag (event, eventId, tagData) {
      pintrk('track', event, merge(tagData, { event_id: eventId, currency: 'USD' }))
    },
    getPinterestTagEvent ({ event_name }) {
      return event_name.replace(/_/g, '').toLowerCase()
    },
    getDefaultConversionData () {
      return {
        event_time: Date.now(),
        source_path: window.location.pathname,
        opt_out: localStorage.getItem('cookie:accepted'),
        wifi: window.navigator.connection?.type === 'wifi',
        language: window.navigator.language
      }
    },
    getProductPageConversionData (product) {
      return {
        custom_data: {
          value: product.total,
          content_name: product.name,
          content_category: product.category,
          content_brand: product.brand,
          contents: [{
            id: product.id,
            item_price: product.price,
            quantity: product.quantity
          }]
        }
      }
    },
    getAddToCartConversionData (order, product) {
      return merge(
        this.getDefaultConversionData(),
        this.getProductPageConversionData(product),
        {
          event_name: 'add_to_cart',
          order_number: order?.number
        }
      )
    },
    getProductPageVisitConversionData (product) {
      return merge(
        this.getDefaultConversionData(),
        this.getProductPageConversionData(product),
        { event_name: 'page_visit' })
    },
    getCheckoutConversionData (order) {
      const metaData = {
        order_number: order?.number,
        event_name: 'checkout',
        custom_data: {
          value: order?.total,
          content_name: 'Checkout',
          contents: order?.line_items?.map(lineItem => {
            return {
              id: lineItem.variant.id,
              item_price: lineItem.price,
              quantity: lineItem.quantity
            }
          })
        }
      }

      return merge(this.getDefaultConversionData(), metaData)
    },
    getSearchConversionData (keyword) {
      const metaData = {
        event_name: 'search',
        custom_data: {
          content_name: 'SearchBox',
          search_string: keyword
        }
      }

      return merge(this.getDefaultConversionData(), metaData)
    },
    getSignupConversionData () {
      const metaData = {
        event_name: 'signup'
      }

      return merge(this.getDefaultConversionData(), metaData)
    },
    getLeadConversionData () {
      const metaData = {
        event_name: 'lead'
      }

      return merge(this.getDefaultConversionData(), metaData)
    },
    getViewCategoryConversionData (name, products) {
      const metaData = {
        event_name: 'view_category',
        custom_data: {
          content_name: name,
          contents: products.map(product => {
            return {
              id: product.objectID,
              item_price: product.price,
              quantity: 1
            }
          })
        }
      }

      return merge(this.getDefaultConversionData(), metaData)
    }
  }
}
