import { Amplify } from 'aws-amplify'
import '@aws-amplify/auth'

export default function({ $config }) {
  Amplify.configure({
    ssr: true,
    Auth: {
      userPoolId: $config.COGNITO_USER_POOL_ID,
      userPoolWebClientId: $config.COGNITO_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        domain: $config.COGNITO_OAUTH_DOMAIN,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: $config.COGNITO_OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: $config.COGNITO_OAUTH_REDIRECT_SIGN_OUT,
        responseType: 'code'
      },
      signUpVerificationMethod: 'code'
    }
  })
}